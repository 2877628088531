module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_babel-plugin-styled-components@2.1.4_@babel+core@7.22._6gpgktphktk5tsdgwadnb3dvoq/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+css@11.10.6_@emotion+react@11.13.0_@types+react@17.0_huw7nlptxvlerdnemiu7mzi6be/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-loadable-components-ssr@4.3.2_@babel+core@7.22.20_@loadable+component@5.16.4_re_nb4bdk4znwe7ovltmadfmwqyvy/node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":false,"preloadTags":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.18.2_patch_hash=fd6mwyghlqzpwa4vwfw2fxs7ny_@types+node_qozchszee4qr7i2v2eb4voq52i/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Yieldstreet Prism Fund - Alternative Investments - Yieldstreet","short_name":"Yieldstreet Prism Fund - Alternative Investments - Yieldstreet","start_url":"/","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7ee3793fb8af98134c2f5b4b2f545b65"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["FontAwesome"],"urls":["https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.18.2_patch_hash=fd6mwyghlqzpwa4vwfw2fxs7ny_@types+node@18.19.31_babel-eslint@10.1.0__acx25gluerjh5b6xgzeabvu5fm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
